<template>
    <div class="new_order">
        <div class="new_order_row">
            <div class="o_title">{{$t('main.text178')}}</div>
            <div class="row_form">
                <div class="set_row1">
                    <span class="u_icon_box">
                        <el-avatar :size="50" :src="user_icon"></el-avatar>
                        <span class="u_txt">{{$t('main.text179')}}</span>
                    </span>
                    <span class="s_row1_name">{{o_row1.name}}</span>
                    <span class="details set_btn" @click="open_set_user_dlg(o_row1.name)">{{$t('main.text182')}}</span>
                </div>
            </div>
        </div>

        <div class="new_order_row">
            <div class="o_title">{{$t('main.text180')}}</div>
            <div class="row_form">
                <el-form :inline="true">
                    <el-form-item class="set_inp_item">
                        <el-input v-model="o_row2.company_name" disabled></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        
        <div class="new_order_row">
            <div class="o_title">{{$t('main.text181')}}</div>
            <div class="row_form">
                <el-form :inline="true">
                    <el-form-item class="set_inp_item">
                        <el-input v-model="o_row2.email" disabled></el-input>
                        <span class="details set_btn" @click="open_set_email_dlg" v-if="role!=5">{{$t('main.text182')}}</span>
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <div class="new_order_row">
            <div class="o_title">{{$t('main.text228')}}</div>
            <div class="row_form">
                <el-form :inline="true">
                    <el-form-item class="set_inp_item">
                        <el-input v-model="o_row2.mobile" disabled :placeholder="$t('main.text183')"></el-input>
                        <span class="details set_btn" @click="open_mobile_num_dlg(o_row2.mobile)">{{$t('main.text184')}}</span>
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <div class="new_order_row">
            <div class="o_title">{{$t('main.text185')}}</div>
            <div class="row_form">
                <el-form>
                    <!-- <div class="s_new4_1">
                        <el-form-item class="s_new4_2">
                            <el-input v-model="o_row4.email_code" placeholder="请填写邮箱验证码"></el-input>
                            <span class="e_code_btn">| 
                                <span v-show="show" class="details e_code_btn2" @click="send_email_btn">获取验证码</span>
                                <span v-show="!show" class="details e_code_btn2">{{count}}</span>
                            </span>
                        </el-form-item>
                    </div> -->
                    <div class="s_new4_1">
                        <el-form-item>
                            <el-input type="password" show-password v-model="o_row4.pwd" :placeholder="$t('main.text186')"></el-input>
                        </el-form-item>
                    </div>
                    <div class="s_new4_1">
                        <el-form-item>
                            <el-input type="password" show-password v-model="o_row4.cfg_pwd" :placeholder="$t('main.text187')"></el-input>
                            <div class="s_new4_3">{{$t('main.text188')}}</div>
                        </el-form-item>
                    </div>

                    <div>
                        <el-form-item>
                            <el-button plain class="blue_bgc pwd_submit" @click="pwd_submit">{{$t('main.text120')}}</el-button>
                        </el-form-item>
                    </div>
                    
                </el-form>
            </div>
        </div>

        <!-- 修改用户名 弹框 -->
        <el-dialog
            title=" "
            :visible.sync="set_user_dlg"
            width="600px"
            top="20%"
            center>
            <div class="dlg_main">
                <el-form>
                    <el-form-item :label="$t('main.text15')">
                        <el-input v-model="row1_dlg.name"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="set_user_dlg_confirm" class="confirm_btn">{{$t('main.text4')}}</el-button>
                <el-button @click="set_user_dlg = false" class="cancel_btn">{{$t('main.text5')}}</el-button>
            </span>
        </el-dialog>

        <!-- 修改邮箱 弹框 -->
        <el-dialog
            :title="$t('main.text189')"
            :visible.sync="set_email_dlg"
            width="600px"
            top="20%"
            center>
            <div class="step_box">
                <el-steps :active="steps_act" align-center>
                    <el-step :title="$t('main.text190')"></el-step>
                    <el-step :title="$t('main.text191')"></el-step>
                    <el-step :title="$t('main.text192')"></el-step>
                </el-steps>
            </div>
            <div class="dlg_main">
                <el-form>
                    <el-form-item v-if="steps_act==1">
                        <el-input v-model.trim="row2_dlg.pwd" type="password" clearable :placeholder="$t('main.text193')"></el-input>
                    </el-form-item>
                    <el-form-item v-else-if="steps_act==2">
                        <el-input v-model.trim="row2_dlg.new_email" clearable :placeholder="$t('main.text194')"></el-input>
                    </el-form-item>
                    <el-form-item v-else-if="steps_act==3">
                        <div class="steps3_txt">{{$t('main.text195')}} {{row2_dlg.new_email}}</div>
                        <el-input v-model.trim="row2_dlg.new_email_code" clearable :placeholder="$t('main.text196')"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="set_email_next" class="confirm_btn next_btn">
                    <span v-if="steps_act==3">{{$t('main.text197')}}</span>
                    <span v-else>{{$t('main.text198')}}</span>
                </el-button>
            </span>
        </el-dialog>

        <!-- 绑定手机号 弹框 -->
        <el-dialog
            title=" "
            :visible.sync="mobile_num_dlg"
            width="600px"
            top="20%"
            center>
            <div class="dlg_main">
                <el-form>
                    <el-form-item :label="$t('main.text228')">
                        <el-input v-model.trim="o_row2_dlg.mobile"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="mobile_num_dlg_confirm" class="confirm_btn">{{$t('main.text4')}}</el-button>
                <el-button @click="mobile_num_dlg = false" class="cancel_btn">{{$t('main.text5')}}</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import {modifyMobile,modifyLoginPassword,send,modifyName,verifyPassword,modifyLoginEmail} from '@/api/api';
import md5 from 'js-md5';
import * as EmailValidator from 'email-validator';
import crypto from "@/crypto/crypto";

export default {
    data() {
        return {
            user_icon:require("../../assets/img/u_icon.png"),
            o_row1:{
                name:'',
                old_name:'',
            },
            o_row2:{
                email:'',
                mobile:'',
                company_name:"",
            },
            o_row2_dlg:{
                mobile:'',
            },
            set_user_dlg:false,
            row1_dlg:{
                name:"",
            },
            mobile_num_dlg:false,
            set_email_dlg:false,
            steps_act:1,
            row2_dlg:{
                pwd:"",
                new_email:"",
                new_email_code:"",
            },
            o_row4:{
                email_code:"",
                pwd:"",
                cfg_pwd:"",
            },
            show: true,//60秒 倒计时
            count: '',
            timer: null,
            role: "", //1管理⽤户；5⾯试官⽤户； ⾯试官⽤户没有账号管理

        }
    },
    beforeMount(){
        if(sessionStorage.getItem("user_info") && crypto.get(sessionStorage.getItem("user_info"))){
            const u_info = crypto.get(sessionStorage.getItem("user_info"))||"";
            if(u_info){
                this.o_row1.name = u_info.username;
                this.o_row2.email = u_info.email;
                this.o_row2.mobile = u_info.mobile;
                this.o_row2.company_name = u_info.company_name;
            }
        }
        this.role = sessionStorage.getItem("role")||'';
    },
    methods:{
        open_set_email_dlg(){
            this.steps_act = 1;
            this.row2_dlg.pwd = "";
            this.row2_dlg.new_email = "";
            this.row2_dlg.new_email_code = "";
            this.set_email_dlg = true;
        },
        async set_email_next(){
            if(this.steps_act==1){
                if(!this.row2_dlg.pwd){
                    return  this.msg("error",this.$t('main.text199'));
                }
                // 正则表达式：字母+数字，长度6-12
                const regex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9(@#$%^&+=~!?_)]{6,18}$/;
                if(!regex.test(this.row2_dlg.pwd)){
                    return  this.msg("error",this.$t('main.text166'));
                }
                const res = await verifyPassword({
                    password:md5(this.row2_dlg.pwd),
                });
                if(res.data.code!=200){
                    return this.msg("error",res.data.msg);
                }
                this.steps_act = 2;
            }else if(this.steps_act==2){
                if(!this.row2_dlg.new_email){
                    return  this.msg("error",this.$t('main.text200'));
                }
                if(!EmailValidator.validate(this.row2_dlg.new_email)){
                    return  this.msg("error",this.$t('main.text42'));
                }
                const res = await send({
                    email:this.row2_dlg.new_email,
                    type:"resetLoginEmail",
                });
                if(res.data.code!=200){
                    return this.msg("error",res.data.msg);
                }
                this.steps_act = 3;
            }else if(this.steps_act==3){
                if(!this.row2_dlg.new_email_code){
                    return  this.msg("error",this.$t('main.text201'));
                }
                const res = await modifyLoginEmail({
                    password:md5(this.row2_dlg.pwd),
                    email:this.row2_dlg.new_email,
                    authCode:this.row2_dlg.new_email_code
                });
                if(res.data.code!=200){
                    return this.msg("error",res.data.msg);
                }
                this.set_email_dlg = false;
                this.steps_act = 1;
                this.bus.$emit("get_user_info",true);
            }
            
        },
        async pwd_submit(){
            // if(!this.o_row4.email_code){
            //     return  this.msg("error",'请填写邮箱验证码');
            // }
            if(!this.o_row4.pwd){
                return  this.msg("error",this.$t('main.text186'));
            }
            // 正则表达式：字母+数字，长度6-12
            const regex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9(@#$%^&+=~!?_)]{6,18}$/;
            if(!regex.test(this.o_row4.pwd)){
                return  this.msg("error",this.$t('main.text166'));
            }
            if(this.o_row4.pwd != this.o_row4.cfg_pwd){
                return  this.msg("error",this.$t('main.text168'));
            }
            // authCode:this.o_row4.email_code,
            const res = await modifyLoginPassword({
                password:md5(this.o_row4.pwd),
            });
            if(res.data.code!=200){
                return this.msg("error",res.data.msg);
            }
            this.msg("success",this.$t('main.text122'));
            sessionStorage.clear();
            this.$router.push('/login');
        },
        open_mobile_num_dlg(mobile){
            this.o_row2_dlg.mobile = mobile;
            this.mobile_num_dlg = true;
        },
        async mobile_num_dlg_confirm(){
            if(! this.o_row2_dlg.mobile){
                return  this.msg("error",this.$t('main.text204'));
            }
            const res = await modifyMobile({
                country_code:"86",
                mobile: this.o_row2_dlg.mobile,
            });
            if(res.data.code!=200){
                return this.msg("error",res.data.msg);
            }
            this.o_row2.mobile = this.o_row2_dlg.mobile;
            this.msg("success",this.$t('main.text45'));
            this.mobile_num_dlg = false;
            this.bus.$emit("get_user_info",true);
        },
        async send_email_btn(){
            if(!this.o_row2.email){
                return false;
            }
            const res = await send({
                email:this.o_row2.email,
                type:"forgetLoginPassword",
            });
            if(res.data.code!=200){
                return this.msg("error",res.data.msg);
            }
            this.getCode();
        },
        getCode(){
            const TIME_COUNT = 60;
            if (!this.timer) {
                this.count = TIME_COUNT;
                this.show = false;
                this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
                } else {
                this.show = true;
                clearInterval(this.timer);
                this.timer = null;
                }
                }, 1000)
            }
        },
        open_set_user_dlg(name){
            this.o_row1.old_name = JSON.parse(JSON.stringify(name));
            this.row1_dlg.name = name;
            this.set_user_dlg=true;
        },
        async set_user_dlg_confirm(){
            if(!this.row1_dlg.name.trim()){
                return this.msg("error",this.$t('main.text205'));
            }
            if(this.row1_dlg.name == this.o_row1.old_name){
                this.set_user_dlg=false;
                return false;
            }
            const res = await modifyName({
                name:this.row1_dlg.name
            });
            if(res.data.code!=200){
                return this.msg("error",res.data.msg);
            }
            this.o_row1.name = this.row1_dlg.name;
            this.msg("success",this.$t('main.text45'));
            this.set_user_dlg = false;
            this.bus.$emit("get_user_info",true);
        }
    }
}
</script>

<style scoped>
.new_order_row{
    border-radius: 6px;
    background: #FFF;
    box-sizing: border-box;
    padding: 30px 20px;
    margin-bottom: 30px;
}
.o_title{
    color: #333;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
}
.row_form >>> .el-form-item__label{
    color: #838383;
}
.row_form >>> .el-form-item:last-child{
    margin-bottom: 0;
}
.parting_line{
    box-sizing: border-box;
    padding: 0 10px;
}
.form_margin_btm{
    margin-bottom: 22px;
}
.hint{
    color: #838383;
    font-size: 12px;
}
.my_label{
    color: #333;
    font-size: 16px;
    margin-right: 35px;
    line-height: 40px;
}
.inp_min_width,
.inp_min_width >>> .el-form-item__content{
    min-width: 300px;
}
.inp_min_width >>> .el-select{
    width: 100%;
}
/* ---- */
.set_inp_item >>>  .el-form-item__content{
    display: flex;
    align-items: center;
}
.set_btn{
    margin-left: 40px;
    min-width: 50px;
}
.set_row1{
    display: flex;
    align-items: center;
}
.s_row1_name{
    margin-left: 16px;
}
.row_form{
    color: #333;
    font-size: 14px;
}
.dlg_main{
    margin: auto;
    width: 350px;
}
.u_icon_box{
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
}
.u_txt{
    position: absolute;
    left: 0;
    bottom: 6px;
    font-size: 12px;
    color: #fff;
    background: #000;
    opacity: 0.6;
    display: block;
    width: 100%;
    text-align: center;
}
.step_box{
    margin-bottom: 45px;
}
.next_btn{
    min-width: 260px !important;
}
.steps3_txt{
    text-align: center;
    color: #A9A9A9;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 45px;
}
.s_new4_1{
    max-width: 350px;
    margin-bottom: 30px;
}
.s_new4_2{
    position: relative;
}
.e_code_btn{    
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #A9A9A9;
    background: #fff;
    height: 96%;
}
.e_code_btn2{
    display: inline-block;
    width: 120px;
    text-align: center;
}
.s_new4_3{
    color: #838383;
    font-size: 14px;
    margin-top: 10px;
    line-height: initial;
}
.pwd_submit{
    min-width: 350px !important;
}

</style>
